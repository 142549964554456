<template>
  <div class="ai-search-form">
    <autosize-textarea
      :initial-text="text"
      class="filter"
      placeholder="Ask AI to search our database for you..."
      @keydown.enter.prevent="submit"
      @input="onInput"
    >
      <div class="submit-container">
        <mcr-button class="small square" :loading="aiSearchPromptLoadingState" :disabled="!text" @click="submit"
          ><send-icon :size="20"></send-icon
        ></mcr-button>
      </div>
    </autosize-textarea>
    <div v-if="showError" class="help-text error">
      We couldn’t process this request. Try specifying details like a name, location, or time period. For example: 'I’m
      looking for census records of Sam Wang in California from the 1920s'.
    </div>
    <div v-else class="help-text text-sm">
      <span class="tip">Tip: </span
      ><span>Enter key details like name, date and location. Keep it simple for AI to find the best matches.</span>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import AutosizeTextarea from '@common/elements/inputs/AutosizeTextarea';
import {getRedirectRouteFromAiSearch} from '@common/pages/searches/helpers/utils';
import SendIcon from 'vue-material-design-icons/Send';
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      text: this.$store.getters.aiSearchQueryState,
      showError: false,
      emptyQueryRedirectRoutes: ['chinese_places_search', 'world_places_search', 'surname_search'],
    };
  },
  computed: {
    ...mapGetters(['aiSearchPromptLoadingState']),
  },
  methods: {
    onInput(event) {
      this.text = event.target.value;
    },
    submit() {
      if (!this.text || this.aiSearchPromptLoadingState) {
        return;
      }

      this.$store
        .dispatch('askAiBySearchPromptAction', {search_prompt: this.text})
        .then(response => {
          this.processResponse(response);
        })
        .catch(() => {
          this.handleSearchError();
        });
    },
    processResponse(response) {
      this.showError = false;
      if (!response.search_name) {
        return this.handleSearchError();
      }
      const route = getRedirectRouteFromAiSearch(response);
      if (!route) {
        return this.handleSearchError();
      }
      const newState = this.emptyQueryRedirectRoutes.includes(response.search_name) ? '' : this.text;
      this.$store.commit('setAiSearchQueryState', newState);
      this.$emit('submit');
      this.redirectToRoute(route);
    },
    redirectToRoute(route) {
      this.$router.push(route);
    },
    handleSearchError() {
      this.showError = true;
    },
  },
  name: 'AiSearchForm',
  components: {McrButton, AutosizeTextarea, SendIcon},
};
</script>

<style scoped lang="scss">
.ai-search-form {
  position: relative;
  .filter {
    display: flex;
    textarea {
      flex-grow: 1;
    }
  }
  .help-text {
    margin-top: 8px;
    .tip {
      font-weight: 600;
      color: $neutral-500;
    }
    &.error {
      font-style: italic;
    }
  }
  .autosize-textarea {
    position: relative;
  }
  .submit-container {
    position: absolute;
    bottom: 12px;
    right: 12px;
  }
}
</style>
